import React, { useEffect, useRef, useState } from "react";
import { Form } from "./Forms/Form";
import { Field } from "./Forms/Field";
import { Checkbox, Input } from "./Forms/Input";
import { useForm } from "react-hook-form";
import Button from "./Button/Button";
import { useStore } from "../../store/useStore";
import { ChevronDownOutlined, CloseCircleIcon, SearchOutlined } from "../OldDesign/Icons";
import InviteFormSuccessPopup from "./Popups/InviteFormSuccessPopup";
import { useLocation, useNavigate } from "react-router-dom";
import { getNetworkConnections } from "../../api/endpoints";
import debounce from "../../utils/debouncer";

const CreateGroupForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      name: state.group?.name,
      description: state.group?.description,
    },
  });
  const toggleSelectAll = useStore((state) => state.toggleSelectAll);
  const toggleUserInSelectedUsers = useStore((state) => state.toggleUserInSelectedUsers);
  const selectedUsers = useStore((state) => state.selectedUsers);
  const createGroup = useStore((state) => state.createGroup);
  const editGroup = useStore((state) => state.editGroup);
  const clearSelectedUsers = useStore((state) => state.clearSelectedUsers);

  const [success, setSuccess] = useState(false);

  const handleCreateGroup = async (formData) => {
    if (selectedUsers.length > 0) {
      const payload = {
        ...formData,
        members: selectedUsers.map(({ id }) => id),
      };
      await createGroup(payload, () => setSuccess(true));
    }
  };

  const handleEditGroup = async (formData) => {
    if (selectedUsers.length > 0) {
      const payload = {
        ...formData,
        user_array: selectedUsers.map(({ id }) => id),
      };
      // console.log(payload);
      await editGroup(state.group.id, payload, () => setSuccess(true));
    }
  };

  useEffect(() => {
    if (state?.isEdit) {
      clearSelectedUsers();
      toggleSelectAll(true, state?.group.members);
    }
    return () => clearSelectedUsers();
  }, [clearSelectedUsers, toggleSelectAll, state]);

  if (success) {
    return <InviteFormSuccessPopup />;
  }

  return (
    <div className="w-[620px] p-9 max-lg:max-w-[358px] max-lg:px-3 max-lg:py-6">
      <button className="absolute right-9 max-lg:right-3" onClick={() => navigate(-1)}>
        <CloseCircleIcon className="h-5 w-5" />
      </button>
      <h5 className="mb-3 border-l-2 border-primary-900 pl-2 text-[32px] font-bold leading-tight text-primary-900 max-lg:text-[18px] max-lg:font-medium">
        {state.isEdit ? "Grup Duzenle" : "Grup Oluştur"}
      </h5>
      <p className="pb-6 text-justify text-[16px] leading-tight text-primary-900 max-lg:pb-4 max-lg:text-[13px]">
        Aşağıdaki bilgileri doldurarak grup oluşturabilirsiniz.
      </p>
      <Form
        onSubmit={state.isEdit ? handleSubmit(handleEditGroup) : handleSubmit(handleCreateGroup)}
        className="flex flex-col gap-4"
      >
        <Field error={errors?.name} label="Grup Adı">
          <Input {...register("name", { required: "Required" })} />
        </Field>
        <Field error={errors?.description} label="Açıklama">
          <Input {...register("description", { required: "Required" })} />
        </Field>
        <Field label="Grup Üyeleri">
          <MultiSelectDropdown selected={selectedUsers} />
        </Field>
        <div className="flex flex-wrap gap-3">
          {selectedUsers.length > 0 ? (
            selectedUsers.map((user) => (
              <div type="button" key={user.id} className="flex items-center gap-1 rounded-3xl bg-primary-200 p-1">
                <img
                  src={user.basic.profile_picture[0].id}
                  alt={user.basic.name[0].id}
                  className="h-8 w-8 rounded-full"
                />
                <p className="whitespace-nowrap text-[14px] font-medium leading-tight text-primary-900">
                  {user.basic.name[0].id}
                </p>
                <button type="button" onClick={() => toggleUserInSelectedUsers(user)}>
                  <CloseCircleIcon className="h-4 w-4" />
                </button>
              </div>
            ))
          ) : (
            <>Grup oluşturmak için en az 1 kişi seçiniz</>
          )}
        </div>
        <Button type="submit" loading={isSubmitting} className="">
          {state.isEdit ? "Grup Duzenle" : "Grup Oluştur"}
        </Button>
      </Form>
    </div>
  );
};

const MultiSelectDropdown = ({ selected }) => {
  const groupSearchRef = useRef();
  const [memberList, setMemberList] = useState([]);
  const [groupSearchKeyword, setGroupSearchKeyword] = useState("");
  const [optsLoading, setOptsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleUserInSelectedUsers = useStore((state) => state.toggleUserInSelectedUsers);

  const toggleDropdown = () => {
    if (isOpen) {
      setGroupSearchKeyword("");
    } else {
      groupSearchRef.current.focus();
    }
    setIsOpen((prev) => !prev);
  };

  const handleChange = debounce((e) => {
    const input = e.target.value;
    if (input.length > 2 || input.length === 0) setGroupSearchKeyword(input);
  }, 500);

  useEffect(() => {
    const options = {
      page: 1,
      rows: 50,
      keyword: groupSearchKeyword,
    };
    getNetworkConnections(
      options,
      null,
      (res) => {
        setMemberList(res.data.rows);
      },
      (err) => console.log(err),
      (bool) => setOptsLoading(bool),
    );
  }, [groupSearchKeyword]);

  return (
    <div className={`relative min-w-[300px] transition-all`}>
      <div
        onClick={toggleDropdown}
        className="flex items-center rounded-lg border border-neutral-300 p-4 hover:border-primary-600 focus:border-4 focus:border-primary-600"
      >
        <SearchOutlined className="h-4 w-4 text-neutral-600" />
        <input
          type="text"
          ref={groupSearchRef}
          className="pl-4 text-[16px] leading-none outline-none max-lg:text-[14px]"
          placeholder={`${selected.length} Seçili`}
          onChange={handleChange}
          // value={groupSearchKeyword}
        />
        <ChevronDownOutlined className="ml-auto h-4 w-4" />
      </div>
      <ul
        className={`${
          isOpen ? " max-h-[200px] border py-2" : " max-h-[0px] border-none py-0"
        } w-full overflow-y-auto rounded-lg border border-neutral-300 bg-neutral-100 px-3 transition-all`}
      >
        {isOpen && optsLoading ? (
          <div className="mx-auto h-4 w-4 animate-spin rounded-full border border-solid border-primary-600 border-t-transparent"></div>
        ) : memberList.length === 0 ? (
          <>Kullanıcı bulunamadı</>
        ) : (
          memberList.map((opt) => {
            const isSelected = selected.some((selectedOpt) => selectedOpt.id === opt.id);
            return (
              <li key={opt.id} className="flex items-center justify-between py-1.5">
                <div className="flex gap-2.5">
                  <img
                    src={opt.basic.profile_picture[0].id}
                    alt={opt.basic.name[0].id}
                    className="h-[50px] w-[50px] rounded-full"
                  />
                  <div className="flex flex-col justify-center">
                    <p className="font-medium leading-tight text-primary-900">{opt.basic.name[0].id}</p>
                    <p className="text-[13px] leading-tight text-primary-900">{opt.work.job[0].name}</p>
                  </div>
                </div>
                <label>
                  <Checkbox
                    onChange={() => toggleUserInSelectedUsers(opt)}
                    checked={isSelected}
                    name={`card-id-${opt.id}`}
                    id={`card-id-${opt.id}`}
                  />
                </label>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};

export default CreateGroupForm;
